import "./ErrorPay.css";
import React from "react";
import telegram from "../../images/telegram.svg";
import whatsapp from "../../images/whatsapp.svg";
import viber from "../../images/viber.svg";
import { useHistory } from "react-router-dom";

function ErrorPay() {
  const history = useHistory();
  function onClose() {
    history.push("/");
  }
  return (
    <section
      className="errorpay"
      onClick={(e) => e.currentTarget === e.target && onClose()}
    >
      <div className="errorpay__box">
        <button
          className="errorpay__close"
          type="button"
          onClick={onClose}
        ></button>
        <h2 className="errorpay__title">
          При оплате бронирования произошла ошибка!
        </h2>
        <p className="errorpay__text">
          Попробуйте еще раз или свяжитесь с нами.
        </p>
        <div className="thanks__box-mess">
          <a className="thanks__link" href="https://t.me/logrus_teatello">
            <img className="thanks__icon" src={telegram} alt="telegram" />
          </a>
          <a className="thanks__link" href="whatsapp://send?phone=79312018040">
            <img className="thanks__icon" src={whatsapp} alt="whatsapp" />
          </a>
          <a className="thanks__link" href="viber://chat?number=+79312018040">
            <img className="thanks__icon" src={viber} alt="viber" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default ErrorPay;
