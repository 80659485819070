import React from "react";
import price1 from "../images/price1.png";
import price2 from "../images/price2.jpg";
import price3 from "../images/price3.png";
import price4 from "../images/price4.jpg";
import price5 from "../images/price5.jpg";
export const PriceContext = React.createContext();

export const price = [
  {
    id: "1",
    src: `${price1}`,
    title: "Будни",
    day: "(Вс-Чт)",
    subtitle: "Самый выгодный вариант",
    price: "12 000",
  },
  {
    id: "2",
    src: `${price2}`,
    title: "Выходные",
    day: "(Пт-Сб)",
    subtitle: "Самый часто бронируемый вариант",
    price: "16 000",
  },
  {
    id: "3",
    src: `${price3}`,
    title: "Праздники",
    day: "",
    subtitle: "Самый незабываемый вариант!",
    price: "18 000",
  },
  {
    id: "4",
    src: `${price4}`,
    title: "Новогодние праздники",
    day: "",
    subtitle: "1-8 января 2025 г.",
    price: "20 000",
  },
  {
    id: "5",
    src: `${price5}`,
    title: "Новый Год",
    day: "",
    subtitle: "",
    price: `2 тарифа`,
  },
];
