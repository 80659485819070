import "./App.css";
import axios from "axios";
import React, { Suspense, Navigate } from "react";
import { Route, Switch } from "react-router-dom";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import { PriceContext, price } from "../../context/PriceContext";
import { IncludeContext, include } from "../../context/IncludeContext";
import { QuestionsContext, questions } from "../../context/QuestionsContext";
import { AlongsideContext, alongside } from "../../context/AlongsideContext";
import { gallery, GalleryContext } from "../../context/GalleryContext";
import Form from "../Form/Form";
import flower from "../../images/flowerpopup.png";
import Policy from "../Policy/Policy";
import Rules from "../Rules/Rules";
import ThanksPay from "../ThanksPay/ThanksPay";
import ErrorPay from "../ErrorPay/ErrorPay";
import Payment from "../Payment/Payment";
import nyg from "../../images/NYG4.png";
import Stocks from "../Stocks/Stocks";

const Calendar = React.lazy(() => import("../Calendar/Calendar"));
const Admin = React.lazy(() => import("../Admin/Admin"));
const Booking = React.lazy(() => import("../Booking/Booking"));
const Gallery = React.lazy(() => import("../Gallery/Gallery"));
const Service = React.lazy(() => import("../Service/Service"));
const Price = React.lazy(() => import("../Price/Price"));
const Reviews = React.lazy(() => import("../Reviews/Reviews"));
const Include = React.lazy(() => import("../Include/Include"));
const Awards = React.lazy(() => import("../Awards/Awards"));
const Video = React.lazy(() => import("../Video/Video"));
const Questions = React.lazy(() => import("../Questions/Questions"));
const Alongside = React.lazy(() => import("../Alongside/Alongside"));
const Contacts = React.lazy(() => import("../Contacts/Contacts"));
const Popup = React.lazy(() => import("../Popup/Popup"));
const Thanks = React.lazy(() => import("../Thanks/Thanks"));

function App() {
  const [isInfo, setInfo] = React.useState(false);
  const [isBookingRules, setBookingRules] = React.useState(false);
  const [payLink, setPayLink] = React.useState("");
  const [isOpenDate, setOpenDate] = React.useState(false);
  const [isOpenPolicy, setOpenPolicy] = React.useState(false);
  const [isOpenRules, setOpenRules] = React.useState(false);
  const [isOpenCall, setOpenCall] = React.useState(false);
  const [isOpenBooking, setOpenBooking] = React.useState(false);
  const [isOpenQuestion, setOpenQuestion] = React.useState(false);
  const [isOpenNY, setOpenNY] = React.useState(false);
  const [isPopupBooking, setPopupBooking] = React.useState(false);
  const [isPopupBookingThanks, setPopupBookingThanks] = React.useState(false);
  const [isOpenBlack, setOpenBlack] = React.useState(false);
  const [isThanks, setThanks] = React.useState(false);
  const [question, setQuestion] = React.useState("");
  const [isServiceHref, setServiceHref] = React.useState(false);
  const [spanQuestion, setSpanQuestion] = React.useState(false);
  const [matrix, setMatrix] = React.useState([]);
  const [period, setPeriod] = React.useState([]);
  const [stage, setStage] = React.useState(1);
  const [jsonPrice, setJsonPrice] = React.useState({});
  const [jsonSale, setJsonSale] = React.useState({});
  const [jsonBooking, setJsonBooking] = React.useState({});
  const [arrBooking, setArrBooking] = React.useState([]);
  const [jsonPeriodPrice, setJsonPeriodPrice] = React.useState({});
  const [arrPeriodPrice, setArrPeriodPrice] = React.useState([]);
  const [place, setPlace] = React.useState(0);
  const [animal, setAnimal] = React.useState(0);
  const [priceBooking, setPriceBooking] = React.useState([]);
  const [vipSalePrice, setVipSalePrice] = React.useState();
  var freeDate = [];
  const [dateIn, setDateIn] = React.useState("Выберите дату заезда");
  const [dateOut, setDateOut] = React.useState("Выберите дату отъезда");
  const [periodOld, setPeriodOld] = React.useState([
    new Date().getDate() +
      "." +
      new Date().getMonth() +
      "." +
      new Date().getFullYear(),
  ]);
  const [periodHover, setPeriodHover] = React.useState([]);
  const [periodHoverActive, setPeriodHoverActive] = React.useState(true);
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  const weekDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
  const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const activeDate = new Date();
  const [year, setYear] = React.useState(activeDate.getFullYear());
  const [month, setMonth] = React.useState(activeDate.getMonth());
  const firstDay =
    new Date(year, month, 1).getDay() === 0
      ? 7
      : new Date(year, month, 1).getDay();
  var maxDays = nDays[month];
  const holiday = [
    "23.2",
    "24.2",
    "25.2",
    "26.2",
    "8.3",
    "1.5",
    "2.5",
    "3.5",
    "4.5",
    "6.5",
    "9.5",
    "10.6",
    "11.6",
    "12.6",
    "4.11",
    "5.11",
    "6.11",
    "3.1",
    "4.1",
    "5.1",
    "6.1",
    "7.1",
    "8.1",
  ];
  const scollToRef = React.useRef();
  React.useEffect(() => {
    axios({
      method: "GET",
      url: "./getData.php",
      headers: {
        "Access-Control-Allow-Headers": "content-type",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setJsonPrice(JSON.parse(res.data[2][0].price));
      var saleKeys = [];
      var priceKeys = [];
      var bookingKeys = [];
      if (Number(res.data[1][0].booking) === 0) {
        setJsonBooking({
          1: {
            date: "0",
          },
        });
      } else {
        setJsonBooking(res.data[1][0].booking);
        Object.keys(res.data[1][0].booking).forEach((keys) => {
          Number(res.data[1][0].booking[keys].date.split(".")[2]) <
          Number(new Date().getFullYear()) ? (
            bookingKeys.push(Number(keys.replace(/["']/g, "")))
          ) : (
            <></>
          );
          Number(res.data[1][0].booking[keys].date.split(".")[2]) ===
            Number(new Date().getFullYear()) &&
          Number(res.data[1][0].booking[keys].date.split(".")[1]) <
            Number(new Date().getMonth() + 1) ? (
            bookingKeys.push(Number(keys.replace(/["']/g, "")))
          ) : (
            <></>
          );
          Number(res.data[1][0].booking[keys].date.split(".")[2]) ===
            Number(new Date().getFullYear()) &&
          Number(res.data[1][0].booking[keys].date.split(".")[1]) ===
            Number(new Date().getMonth() + 1) &&
          Number(res.data[1][0].booking[keys].date.split(".")[0]) <
            Number(new Date().getDate()) ? (
            bookingKeys.push(Number(keys.replace(/["']/g, "")))
          ) : (
            <></>
          );
        });
        axios({
          method: "POST",
          url: "./deleteOldDate.php",
          headers: {
            "Access-Control-Allow-Headers": "content-type",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: { keySales: 0, keyBooking: bookingKeys.join(","), keyPrice: 0 },
        });
      }
      if (Number(res.data[0][0].sale) === 0) {
        setJsonSale({
          1: {
            date: "0",
            sale: "0",
          },
        });
      } else {
        setJsonSale(res.data[0][0].sale);
        Object.keys(res.data[0][0].sale).forEach((keys) => {
          Number(res.data[0][0].sale[keys].date.split(".")[2]) <
          Number(new Date().getFullYear()) ? (
            saleKeys.push(Number(keys.replace(/["']/g, "")))
          ) : (
            <></>
          );
          Number(res.data[0][0].sale[keys].date.split(".")[2]) ===
            Number(new Date().getFullYear()) &&
          Number(res.data[0][0].sale[keys].date.split(".")[1]) <
            Number(new Date().getMonth() + 1) ? (
            saleKeys.push(Number(keys.replace(/["']/g, "")))
          ) : (
            <></>
          );
          Number(res.data[0][0].sale[keys].date.split(".")[2]) ===
            Number(new Date().getFullYear()) &&
          Number(res.data[0][0].sale[keys].date.split(".")[1]) ===
            Number(new Date().getMonth() + 1) &&
          Number(res.data[0][0].sale[keys].date.split(".")[0]) <
            Number(new Date().getDate()) ? (
            saleKeys.push(Number(keys.replace(/["']/g, "")))
          ) : (
            <></>
          );
        });
        axios({
          method: "POST",
          url: "./deleteOldDate.php",
          headers: {
            "Access-Control-Allow-Headers": "content-type",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: { keySales: saleKeys.join(","), keyBooking: 0, keyPrice: 0 },
        });
      }
      if (Number(res.data[3][0].periodprice) === 0) {
        setJsonPeriodPrice({
          1: {
            date: "0",
            price: "0",
          },
        });
      } else {
        setJsonPeriodPrice(res.data[3][0].periodprice);
        Object.keys(res.data[3][0].periodprice).forEach((keys) => {
          Number(res.data[3][0].periodprice[keys].date.split(".")[2]) <
          Number(new Date().getFullYear()) ? (
            priceKeys.push(Number(keys.replace(/["']/g, "")))
          ) : (
            <></>
          );
          Number(res.data[3][0].periodprice[keys].date.split(".")[2]) ===
            Number(new Date().getFullYear()) &&
          Number(res.data[3][0].periodprice[keys].date.split(".")[1]) <
            Number(new Date().getMonth() + 1) ? (
            priceKeys.push(Number(keys.replace(/["']/g, "")))
          ) : (
            <></>
          );
          Number(res.data[3][0].periodprice[keys].date.split(".")[2]) ===
            Number(new Date().getFullYear()) &&
          Number(res.data[3][0].periodprice[keys].date.split(".")[1]) ===
            Number(new Date().getMonth() + 1) &&
          Number(res.data[3][0].periodprice[keys].date.split(".")[0]) <
            Number(new Date().getDate()) ? (
            priceKeys.push(Number(keys.replace(/["']/g, "")))
          ) : (
            <></>
          );
        });
        axios({
          method: "POST",
          url: "./deleteOldDate.php",
          headers: {
            "Access-Control-Allow-Headers": "content-type",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: { keySales: 0, keyBooking: 0, keyPrice: priceKeys.join(",") },
        });
      }

      //   setJsonSale({
      //     1: {
      //       date: "16.10.2023",
      //       sale: "200",
      //     },
      //   });
      //   setJsonPrice({
      //     priceDayoff: "16000",
      //     priceHoliday: "18000",
      //     priceWeekday: "12000",
      //   });
      //   setJsonBooking({
      //     1: {
      //       date: "1.9.2023",
      //     },
      //     2: {
      //       date: "4.8.2023",
      //     },
      //     3: {
      //       date: "5.8.2023",
      //     },
      //     4: {
      //       date: "6.8.2023",
      //     },
      //     5: {
      //       date: "9.8.2023",
      //     },
      //     6: {
      //       date: "10.8.2023",
      //     },
      //     7: {
      //       date: "11.8.2023",
      //     },
      //     8: {
      //       date: "17.8.2023",
      //     },
      //     9: {
      //       date: "18.8.2023",
      //     },
      //     10: {
      //       date: "19.7.2023",
      //     },
      //     11: {
      //       date: "20.7.2023",
      //     },
      //     12: {
      //       date: "21.7.2023",
      //     },
      //     13: {
      //       date: "22.7.2023",
      //     },
      //     14: {
      //       date: "23.7.2023",
      //     },
      //     15: {
      //       date: "24.7.2023",
      //     },
      //     16: {
      //       date: "25.7.2023",
      //     },
      //     17: {
      //       date: "26.7.2023",
      //     },
      //     18: {
      //       date: "27.7.2023",
      //     },
      //     19: {
      //       date: "28.7.2023",
      //     },
      //     20: {
      //       date: "29.7.2023",
      //     },
      //     21: {
      //       date: "30.7.2023",
      //     },
      //     22: {
      //       date: "31.7.2023",
      //     },
      //  });
      //  setJsonPeriodPrice({
      //    1: {
      //      date: "4.6.2024",
      //      price: "200",
      //    },
      //    2: {
      //      date: "5.6.2024",
      //      price: "200",
      //    },
      //    3: {
      //      date: "6.6.2024",
      //      price: "200",
      //    },
      //    4: {
      //      date: "7.6.2024",
      //      price: "200",
      //    },
      //    5: {
      //      date: "1.8.2024",
      //      price: "200",
      //    },
      //    6: {
      //      date: "31.7.2024",
      //      price: "200",
      //    },
      //  });
    });
  }, []);
  React.useEffect(() => {
    if (Object.keys(jsonBooking).length !== 0) {
      Object.keys(jsonBooking).map((key) =>
        setArrBooking((arrBooking) => [...arrBooking, jsonBooking[key].date])
      );
    }
  }, [jsonBooking]);
  React.useEffect(() => {
    if (Object.keys(jsonPeriodPrice).length !== 0) {
      Object.keys(jsonPeriodPrice).map((key) =>
        setArrPeriodPrice((arrPeriodPrice) => [
          ...arrPeriodPrice,
          jsonPeriodPrice[key].date,
        ])
      );
    }
  }, [jsonPeriodPrice]);
  React.useEffect(() => {
    if (
      Object.keys(jsonSale).length !== 0 &&
      Object.keys(jsonPrice).length !== 0 &&
      Object.keys(jsonBooking).length !== 0 &&
      arrBooking.length !== 0 &&
      Object.keys(jsonPeriodPrice).length !== 0 &&
      arrPeriodPrice.length !== 0
    ) {
      generateMatrix();
    }
  }, [
    jsonPrice,
    jsonSale,
    jsonBooking,
    month,
    year,
    arrBooking,
    jsonPeriodPrice,
    arrPeriodPrice,
  ]);
  React.useEffect(() => {
    var copy = [];
    var copysale = [];
    if (dateIn === "Выберите дату заезда") {
      setPriceBooking(0);
    } else if (
      Object.keys(jsonSale).length !== 0 ||
      Object.keys(jsonBooking).length !== 0 ||
      Object.keys(jsonPeriodPrice).length !== 0
    ) {
      period.forEach((p) => {
        Object.keys(jsonSale).forEach((keys) => {
          copysale.push(jsonSale[keys].date);
        });
        copysale.includes(`${p}`)
          ? Object.keys(jsonSale).forEach((keys) => {
              jsonSale[keys].date === p ? (
                copy.push(jsonSale[keys].sale.replace(/\s+/g, ""))
              ) : (
                <></>
              );
            })
          : arrPeriodPrice.includes(`${p}`)
          ? Object.keys(jsonPeriodPrice).forEach((keys) => {
              jsonPeriodPrice[keys].date === p ? (
                copy.push(jsonPeriodPrice[keys].price.replace(/\s+/g, ""))
              ) : (
                <></>
              );
            })
          : holiday.includes(`${p.split(".")[0]}.${p.split(".")[1]}`)
          ? holiday.forEach((h) => {
              h === `${p.split(".")[0]}.${p.split(".")[1]}` ? (
                copy.push(jsonPrice.priceHoliday.replace(/\s+/g, ""))
              ) : (
                <></>
              );
            })
          : new Date(
              p.split(".")[2],
              Number(p.split(".")[1]) - 1,
              p.split(".")[0]
            ).getDay() === 5 ||
            new Date(
              p.split(".")[2],
              Number(p.split(".")[1]) - 1,
              p.split(".")[0]
            ).getDay() === 6
          ? copy.push(jsonPrice.priceDayoff.replace(/\s+/g, ""))
          : copy.push(jsonPrice.priceWeekday.replace(/\s+/g, ""));

        console.log(copy);

        period.length === 1
          ? setPriceBooking(
              Number(
                copy.reduce((partialSum, a) => Number(partialSum) + Number(a))
              )
            )
          : setPriceBooking(
              Number(
                copy.reduce((partialSum, a) => Number(partialSum) + Number(a))
              ) - Number(copy[copy.length - 1])
            );
      });
    }
  }, [
    place,
    animal,
    dateIn,
    dateOut,
    period,
    jsonBooking,
    jsonPrice,
    jsonSale,
    jsonPeriodPrice,
    holiday,
  ]);
  React.useEffect(() => {
    var copy = [];
    var copysale = [];
    if (
      Object.keys(jsonSale).length !== 0 ||
      Object.keys(jsonBooking).length !== 0 ||
      Object.keys(jsonPeriodPrice).length !== 0
    ) {
      period.forEach((p) => {
        Object.keys(jsonSale).forEach((keys) => {
          copysale.push(jsonSale[keys].date);
        });
        copysale.includes(
          `${p.split(".")[0]}.${p.split(".")[1]}.${p.split(".")[2]}`
        )
          ? Object.keys(jsonSale).forEach((keys) => {
              jsonSale[keys].date === p ? (
                copy.push({
                  price: jsonSale[keys].sale.replace(/\s+/g, ""),
                  weekday: `${new Date(
                    p.split(".")[2],
                    Number(p.split(".")[1] - 1),
                    p.split(".")[0]
                  ).getDay()}`,
                })
              ) : (
                <></>
              );
            })
          : arrPeriodPrice.includes(`${p}`)
          ? Object.keys(jsonPeriodPrice).forEach((keys) => {
              jsonPeriodPrice[keys].date === p ? (
                copy.push({
                  price: jsonPeriodPrice[keys].price.replace(/\s+/g, ""),
                  weekday: `${new Date(
                    p.split(".")[2],
                    Number(p.split(".")[1] - 1),
                    p.split(".")[0]
                  ).getDay()}`,
                })
              ) : (
                <></>
              );
            })
          : new Date(
              p.split(".")[2],
              Number(p.split(".")[1] - 1),
              p.split(".")[0]
            ).getDay() === 5 ||
            new Date(
              p.split(".")[2],
              Number(p.split(".")[1] - 1),
              p.split(".")[0]
            ).getDay() === 6
          ? copy.push({
              price: jsonPrice.priceDayoff.replace(/\s+/g, ""),
              weekday: `${new Date(
                p.split(".")[2],
                Number(p.split(".")[1] - 1),
                p.split(".")[0]
              ).getDay()}`,
            })
          : holiday.includes(`${p.split(".")[0]}.${p.split(".")[1]}`)
          ? holiday.forEach((h) => {
              h === `${p.split(".")[0]}.${p.split(".")[1]}` ? (
                copy.push({
                  price: jsonPrice.priceHoliday.replace(/\s+/g, ""),
                  weekday: "0",
                })
              ) : (
                <></>
              );
            })
          : copy.push({
              price: jsonPrice.priceWeekday.replace(/\s+/g, ""),
              weekday: `${new Date(
                p.split(".")[2],
                Number(p.split(".")[1] - 1),
                p.split(".")[0]
              ).getDay()}`,
            });
      });
      var copy2 = [];
      copy.forEach((a) => {
        a.weekday === "6" || a.weekday === "0" || a.weekday === "5"
          ? copy2.push(a.price - a.price * 0.15)
          : copy2.push(a.price - a.price * 0.2);
      });
      period.length === 1
        ? setVipSalePrice(
            Number(
              copy2.reduce((partialSum, a) => Number(partialSum) + Number(a))
            )
          )
        : setVipSalePrice(
            Number(
              copy2.reduce((partialSum, a) => Number(partialSum) + Number(a))
            ) - Number(copy2[copy2.length - 1])
          );
    }
  }, [priceBooking]);
  React.useEffect(() => {
    let periodArr = [];
    if (/[0-9]/.test(dateIn.split(".").join("")) === false) {
    } else {
      if (/[0-9]/.test(dateOut.split(".").join("")) === false) {
        periodArr.push(
          Number(dateIn.split(".")[0]) +
            "." +
            Number(dateIn.split(".")[1]) +
            "." +
            Number(dateIn.split(".")[2])
        );
      } else {
        if (
          Number(dateOut.split(".")[1]) - Number(dateIn.split(".")[1]) + 1 >=
          2
        ) {
          for (
            var i = Number(dateIn.split(".")[0]);
            i <= nDays[Number(dateIn.split(".")[1]) - 1];
            i++
          ) {
            periodArr.push(
              i +
                "." +
                Number(dateIn.split(".")[1]) +
                "." +
                Number(dateIn.split(".")[2])
            );
          }
          for (
            var i = Number(dateIn.split(".")[1]) + 1;
            i <= Number(dateOut.split(".")[1]) - 1;
            i++
          ) {
            for (var day = 1; day <= nDays[i - 1]; day++) {
              periodArr.push(
                day + "." + Number(i) + "." + Number(dateOut.split(".")[2])
              );
            }
          }
          for (var i = 1; i <= Number(dateOut.split(".")[0]); i++) {
            periodArr.push(
              i +
                "." +
                Number(dateOut.split(".")[1]) +
                "." +
                Number(dateOut.split(".")[2])
            );
          }
        } else if (
          Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])
        ) {
          for (
            var i = Number(dateIn.split(".")[0]);
            i <= nDays[Number(dateIn.split(".")[1]) - 1];
            i++
          ) {
            periodArr.push(
              i +
                "." +
                Number(dateIn.split(".")[1]) +
                "." +
                Number(dateIn.split(".")[2])
            );
          }
          for (var i = 1; i <= Number(dateOut.split(".")[0]); i++) {
            periodArr.push(
              i +
                "." +
                Number(dateOut.split(".")[1]) +
                "." +
                dateOut.split(".")[2]
            );
          }
        } else if (
          Number(dateIn.split(".")[0]) < Number(dateOut.split(".")[0]) &&
          Number(dateIn.split(".")[1]) < Number(dateOut.split(".")[1])
        ) {
          for (
            var i = Number(dateIn.split(".")[0]);
            i <= nDays[Number(dateIn.split(".")[1]) - 1];
            i++
          ) {
            periodArr.push(
              i +
                "." +
                Number(dateIn.split(".")[1]) +
                "." +
                Number(dateIn.split(".")[2])
            );
          }
          for (var i = 1; i <= Number(dateOut.split(".")[0]); i++) {
            periodArr.push(
              i +
                "." +
                Number(dateOut.split(".")[1]) +
                "." +
                Number(dateOut.split(".")[2])
            );
          }
        } else if (
          Number(dateIn.split(".")[0]) > Number(dateOut.split(".")[0])
        ) {
          for (
            var i = Number(dateIn.split(".")[0]);
            i <= nDays[Number(dateIn.split(".")[1]) - 1];
            i++
          ) {
            periodArr.push(
              i +
                "." +
                Number(dateIn.split(".")[1]) +
                "." +
                Number(dateIn.split(".")[2])
            );
          }
          for (var i = 1; i <= Number(dateOut.split(".")[0]); i++) {
            periodArr.push(
              i +
                "." +
                Number(dateOut.split(".")[1]) +
                "." +
                Number(dateOut.split(".")[2])
            );
          }
        } else {
          for (
            var i = Number(dateIn.split(".")[0]);
            i <= Number(dateOut.split(".")[0]);
            i++
          ) {
            periodArr.push(
              i +
                "." +
                Number(dateIn.split(".")[1]) +
                "." +
                Number(dateIn.split(".")[2])
            );
          }
        }
      }
      setPeriod(periodArr);
    }
  }, [dateIn, dateOut]);
  React.useEffect(() => {
    if (
      Object.keys(jsonSale).length !== 0 &&
      Object.keys(jsonPrice).length !== 0 &&
      Object.keys(jsonBooking).length !== 0
    ) {
      generateMatrix();
    }
  }, [dateIn]);
  if (month === 1) {
    // February
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      maxDays += 1;
    }
  }

  function generateMatrix() {
    const matrix = [];
    // Create header
    matrix[0] = weekDays;
    var counter = 1;
    var lastdate = new Date(year - 1, month + 1, 1).getDate();
    var arb = [];
    arrBooking.map((ab) =>
      Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
      Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
        ? arb.push(ab)
        : null
    );
    if (arb.length === 0) {
      arrBooking.map((ab) =>
        Number(ab.split(".")[1]) > Number(dateIn.split(".")[1])
          ? arb.push(ab)
          : null
      );
    }
    for (var row = 1; row < 7; row++) {
      matrix[row] = [];
      for (var col = 0; col < 7; col++) {
        row === 1 && col === 0 && firstDay === 2
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11]).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(year - 1, 11, nDays[11]).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11]
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11]
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11]).getDate()}.12`
                    ) === `${new Date(year - 1, 11, nDays[11]).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1]).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1]
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1]
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1]
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1]
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 1 && firstDay === 3
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11]).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(year - 1, 11, nDays[11]).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11]
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11]
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11]).getDate()}.12`
                    ) === `${new Date(year - 1, 11, nDays[11]).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1]).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1]
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1]
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1]
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1]
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 0 && firstDay === 3
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 1).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 1
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 1).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 1
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 1
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 1
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 0 && firstDay === 4
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 2).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 2
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 2
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 2
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 2).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 2).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 2).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 2
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 2
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 2
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 2
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 2
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 1 && firstDay === 4
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 1).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 1
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 1).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 1
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 1
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 1
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 2 && firstDay === 4
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11]).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(year - 1, 11, nDays[11]).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11]
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11]
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11]).getDate()}.12`
                    ) === `${new Date(year - 1, 11, nDays[11]).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1]).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1]
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1]
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1]
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1]
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 0 && firstDay === 5
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 3).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 3
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 3
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 3
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 3
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 3
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 3).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 3
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 3).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 3).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 3).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 3).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 3).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 3
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 3
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 3
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 3
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 3
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 3
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 3
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 3
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 3
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 3
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 3
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 1 && firstDay === 5
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 2).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 2
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 2
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 2
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 2).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 2).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 2).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 2
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 2
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 2
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 2
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 2
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 2 && firstDay === 5
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 1).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 1
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 1).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 1
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 1
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 1
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 3 && firstDay === 5
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11]).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(year - 1, 11, nDays[11]).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11]
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11]
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11]).getDate()}.12`
                    ) === `${new Date(year - 1, 11, nDays[11]).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1]).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1]
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1]
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1]
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1]
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 0 && firstDay === 6
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 4).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 4
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 4
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 4
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 4
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 4
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 4).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 4
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 4).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 4).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 4).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 4).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 4).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 4
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 4
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 4
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 4
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 4
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 4
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 4
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 4
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 4
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, 11, nDays[month - 1] - 4).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 4
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 1 && firstDay === 6
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 3).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 3
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 3
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 3
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 3
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 3
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 3).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 3
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 3).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 3).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 3).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 3).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 3).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 3
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 3
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 3
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 3
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 3
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 3
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 3
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 3
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 3
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 3
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 3
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 2 && firstDay === 6
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 2).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 2
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 2
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 2
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 2).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 2).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 2).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 2
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 2
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 2
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 2
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 2
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 3 && firstDay === 6
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 1).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 1
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 1).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 1
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 1
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 1
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 4 && firstDay === 6
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11]).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(year - 1, 11, nDays[11]).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11]
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11]
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11]).getDate()}.12`
                    ) === `${new Date(year - 1, 11, nDays[11]).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1]).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1]
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1]
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1]
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1]
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 0 && firstDay === 7
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 5).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 5
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 5
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 5
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 5
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 5
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 5).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 5
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 5).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 5).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 5).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 5).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 5).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 5
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 5
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 5
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 5
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 5
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 5
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 5
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 5
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 5
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 5
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 5
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 1 && firstDay === 7
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 4).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 4
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 4
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 4
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 4
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 4
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 4).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 4
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 4).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 4).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 4).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 4).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 4).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 4
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 4
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 4
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 4
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 4
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 4
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 4
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 4
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 4
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 4
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 4
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 2 && firstDay === 7
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 3).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 3
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 3
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 3
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 3
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 3
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 3).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 3
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 3).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 3).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 3).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 3).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 3).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 3
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 3
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 3
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 3
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 3
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 3
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 3
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 3
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 3
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 3
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 3
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 3 && firstDay === 7
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 2).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 2
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 2
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 2
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 2
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 2).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 2).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 2).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 2).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 2
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 2
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 2
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 2
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 2
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 2
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 2
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 4 && firstDay === 7
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11] - 1).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11] - 1
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11] - 1
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year - 1,
                            11,
                            nDays[11] - 1
                          ).getDate()}.12.${year - 1}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12`
                    ) ===
                    `${new Date(year - 1, 11, nDays[11] - 1).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11] - 1).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1] - 1).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1] - 1
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1] - 1
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1] - 1
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1] - 1
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1] - 1
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(
                          year,
                          month - 1,
                          nDays[month - 1] - 1
                        ).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row === 1 && col === 5 && firstDay === 7
          ? month === 0
            ? (matrix[row][col] = {
                day: new Date(year - 1, 11, nDays[11]).getDate(),
                year: year - 1,
                month: 11,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(year - 1, 11, nDays[11]).getDate() &&
                                Number(dateIn.split(".")[1]) === 12 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year - 1,
                                    11,
                                    nDays[11]
                                  ).getDate()}.12.${year - 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year - 1,
                                      11,
                                      nDays[11]
                                    ).getDate()}.12.${year - 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                    year - 1
                  }`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(year - 1, 11, nDays[11]).getDate()}.12.${
                            year - 1
                          }`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(year - 1, 11, nDays[11]).getDate()}.12`
                    ) === `${new Date(year - 1, 11, nDays[11]).getDate()}.12}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year - 1,
                        11,
                        new Date(year - 1, 11, nDays[11]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: new Date(year, month - 1, nDays[month - 1]).getDate(),
                year: year,
                month: month - 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) <
                                  new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate() &&
                                Number(dateIn.split(".")[1]) === month &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) ===
                                  `${new Date(
                                    year,
                                    month - 1,
                                    nDays[month - 1]
                                  ).getDate()}.${month}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) ===
                                    `${new Date(
                                      year,
                                      month - 1,
                                      nDays[month - 1]
                                    ).getDate()}.${month}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${new Date(
                    year,
                    month - 1,
                    nDays[month - 1]
                  ).getDate()}.${month}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${new Date(
                            year,
                            month - 1,
                            nDays[month - 1]
                          ).getDate()}.${month}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) =>
                        str ===
                        `${new Date(
                          year,
                          month - 1,
                          nDays[month - 1]
                        ).getDate()}.${month}`
                    ) ===
                    `${new Date(
                      year,
                      month - 1,
                      nDays[month - 1]
                    ).getDate()}.${month}}`
                  ? jsonPrice.priceHoliday
                  : Number(
                      new Date(
                        year,
                        11,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 5 ||
                    Number(
                      new Date(
                        year,
                        month - 1,
                        new Date(year, month - 1, nDays[month - 1]).getDate()
                      ).getDay()
                    ) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : row > 1 && counter > maxDays
          ? month === 11
            ? (matrix[row][col] = {
                day: lastdate++,
                year: year + 1,
                month: 0,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date === `${lastdate - 1}.1.${year + 1}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key === `${lastdate - 1}.1.${year + 1}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) < lastdate - 1 &&
                                Number(dateIn.split(".")[1]) === 1 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) === `${lastdate - 1}.1.${year + 1}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) === `${lastdate - 1}.1.${year + 1}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${lastdate - 1}.${month + 2}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${lastdate - 1}.${month + 2}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find((str) => str === `${lastdate - 1}.1`) ===
                    `${lastdate - 1}.1`
                  ? jsonPrice.priceHoliday
                  : Number(new Date(year + 1, 0, lastdate - 1).getDay()) ===
                      5 ||
                    Number(new Date(year + 1, 0, lastdate - 1).getDay()) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
            : (matrix[row][col] = {
                day: lastdate++,
                year: year,
                month: month + 1,
                sale:
                  jsonSale === 0
                    ? 0
                    : Object.keys(jsonSale)
                        .map((key) =>
                          jsonSale[key].date ===
                          `${lastdate - 1}.${month + 2}.${year}`
                            ? jsonSale[key].sale
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                booking:
                  jsonBooking === 0
                    ? 0
                    : arrBooking
                        .map((key) =>
                          key === `${lastdate - 1}.${month + 2}.${year}`
                            ? dateIn != "Выберите дату заезда"
                              ? Number(dateIn.split(".")[0]) < lastdate - 1 &&
                                Number(dateIn.split(".")[1]) === month + 2 &&
                                arb.length !== 0 &&
                                arb.reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                }) === `${lastdate - 1}.${month + 2}.${year}`
                                ? 0
                                : arb.length !== 0 &&
                                  arb
                                    .reduce(function (prev, curr) {
                                      return Math.abs(
                                        curr.split(".")[0] -
                                          dateIn.split(".")[0]
                                      ) <
                                        Math.abs(
                                          prev.split(".")[0] -
                                            dateIn.split(".")[0]
                                        )
                                        ? curr
                                        : prev;
                                    })
                                    .split(".")[1] > dateIn.split(".")[1] &&
                                  arb.reduce(function (a, b) {
                                    return Number(a.split(".")[0]) <
                                      Number(b.split(".")[0])
                                      ? a
                                      : b;
                                  }) === `${lastdate - 1}.${month + 2}.${year}`
                                ? 0
                                : 1
                              : 1
                            : 0
                        )
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a)
                        ),
                price: arrPeriodPrice.includes(
                  `${lastdate - 1}.${month + 2}.${year}`
                )
                  ? Object.keys(jsonPeriodPrice)
                      .map((keys) => {
                        if (
                          jsonPeriodPrice[keys].date ===
                          `${lastdate - 1}.${month + 2}.${year}`
                        ) {
                          return jsonPeriodPrice[keys].price;
                        } else {
                          return 0;
                        }
                      })
                      .reduce((partialSum, a) => Number(partialSum) + Number(a))
                  : holiday.find(
                      (str) => str === `${lastdate - 1}.${month + 2}`
                    ) === `${lastdate - 1}.${month + 2}`
                  ? jsonPrice.priceHoliday
                  : Number(new Date(year, 1, lastdate - 1).getDay()) === 5 ||
                    Number(new Date(year, 1, lastdate - 1).getDay()) === 6
                  ? jsonPrice.priceDayoff
                  : jsonPrice.priceWeekday,
              })
          : (matrix[row][col] = -1);
        if (row === 1 && col + 1 >= firstDay) {
          // Fill in rows only after the first day of the month
          matrix[row][col] = {
            day: counter++,
            year: year,
            month: month,
            sale:
              jsonSale === 0
                ? 0
                : Object.keys(jsonSale)
                    .map((key) =>
                      jsonSale[key].date ===
                      `${counter - 1}.${month + 1}.${year}`
                        ? jsonSale[key].sale
                        : 0
                    )
                    .reduce((partialSum, a) => Number(partialSum) + Number(a)),
            booking:
              jsonBooking === 0
                ? 0
                : arrBooking
                    .map((key) =>
                      key === `${counter - 1}.${month + 1}.${year}`
                        ? dateIn != "Выберите дату заезда"
                          ? Number(dateIn.split(".")[0]) < counter - 1 &&
                            Number(dateIn.split(".")[1]) === month + 1 &&
                            arb.length !== 0 &&
                            arb.reduce(function (prev, curr) {
                              return Math.abs(
                                curr.split(".")[0] - dateIn.split(".")[0]
                              ) <
                                Math.abs(
                                  prev.split(".")[0] - dateIn.split(".")[0]
                                )
                                ? curr
                                : prev;
                            }) === `${counter - 1}.${month + 1}.${year}`
                            ? 0
                            : arb.length !== 0 &&
                              arb
                                .reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                })
                                .split(".")[1] > dateIn.split(".")[1] &&
                              arb.reduce(function (a, b) {
                                return Number(a.split(".")[0]) <
                                  Number(b.split(".")[0])
                                  ? a
                                  : b;
                              }) === `${counter - 1}.${month + 1}.${year}`
                            ? 0
                            : 1
                          : 1
                        : 0
                    )
                    .reduce((partialSum, a) => Number(partialSum) + Number(a)),
            price: arrPeriodPrice.includes(
              `${counter - 1}.${month + 1}.${year}`
            )
              ? Object.keys(jsonPeriodPrice)
                  .map((keys) => {
                    if (
                      jsonPeriodPrice[keys].date ===
                      `${counter - 1}.${month + 1}.${year}`
                    ) {
                      return jsonPeriodPrice[keys].price;
                    } else {
                      return 0;
                    }
                  })
                  .reduce((partialSum, a) => Number(partialSum) + Number(a))
              : holiday.find((str) => str === `${counter - 1}.${month + 1}`) ===
                `${counter - 1}.${month + 1}`
              ? jsonPrice.priceHoliday
              : Number(new Date(year, month, counter - 1).getDay()) === 5 ||
                Number(new Date(year, month, counter - 1).getDay()) === 6
              ? jsonPrice.priceDayoff
              : jsonPrice.priceWeekday,
          };
        } else if (row > 1 && counter <= maxDays) {
          // Fill in rows only if the counter’s not greater than
          // the number of days in the month
          matrix[row][col] = {
            day: counter++,
            year: year,
            month: month,
            sale:
              jsonSale === 0
                ? 0
                : Object.keys(jsonSale)
                    .map((key) =>
                      jsonSale[key].date ===
                      `${counter - 1}.${month + 1}.${year}`
                        ? jsonSale[key].sale
                        : 0
                    )
                    .reduce((partialSum, a) => Number(partialSum) + Number(a)),
            booking:
              jsonBooking === 0
                ? 0
                : arrBooking
                    .map((key) =>
                      key === `${counter - 1}.${month + 1}.${year}`
                        ? dateIn != "Выберите дату заезда"
                          ? Number(dateIn.split(".")[0]) < counter - 1 &&
                            Number(dateIn.split(".")[1]) === month + 1 &&
                            arb.length !== 0 &&
                            arb.reduce(function (prev, curr) {
                              return Math.abs(
                                curr.split(".")[0] - dateIn.split(".")[0]
                              ) <
                                Math.abs(
                                  prev.split(".")[0] - dateIn.split(".")[0]
                                )
                                ? curr
                                : prev;
                            }) === `${counter - 1}.${month + 1}.${year}`
                            ? console.log(0)
                            : arb.length !== 0 &&
                              arb
                                .reduce(function (prev, curr) {
                                  return Math.abs(
                                    curr.split(".")[0] - dateIn.split(".")[0]
                                  ) <
                                    Math.abs(
                                      prev.split(".")[0] - dateIn.split(".")[0]
                                    )
                                    ? curr
                                    : prev;
                                })
                                .split(".")[1] > dateIn.split(".")[1] &&
                              arb.reduce(function (a, b) {
                                return Number(a.split(".")[0]) <
                                  Number(b.split(".")[0])
                                  ? a
                                  : b;
                              }) === `${counter - 1}.${month + 1}.${year}`
                            ? console.log(1)
                            : 1
                          : 1
                        : 0
                    )
                    .reduce((partialSum, a) => Number(partialSum) + Number(a)),
            price: arrPeriodPrice.includes(
              `${counter - 1}.${month + 1}.${year}`
            )
              ? Object.keys(jsonPeriodPrice)
                  .map((keys) => {
                    if (
                      jsonPeriodPrice[keys].date ===
                      `${counter - 1}.${month + 1}.${year}`
                    ) {
                      return jsonPeriodPrice[keys].price;
                    } else {
                      return 0;
                    }
                  })
                  .reduce((partialSum, a) => Number(partialSum) + Number(a))
              : holiday.find((str) => str === `${counter - 1}.${month + 1}`) ===
                `${counter - 1}.${month + 1}`
              ? jsonPrice.priceHoliday
              : Number(new Date(year, month, counter - 1).getDay()) === 5 ||
                Number(new Date(year, month, counter - 1).getDay()) === 6
              ? jsonPrice.priceDayoff
              : jsonPrice.priceWeekday,
          };
        }
      }
    }
    setMatrix(matrix);
  }
  function changeDate(d) {
    let arr = [];
    let periodArr = [];
    var data = [];
    Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
    var arb = [];
    if (dateIn !== "Выберите дату заезда") {
      arrBooking.map((ab) =>
        Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
        Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
          ? arb.push(ab)
          : null
      );
      if (arb.length === 0) {
        arrBooking.map((ab) =>
          Number(ab.split(".")[1]) > Number(dateIn.split(".")[1])
            ? arb.push(ab)
            : null
        );
      }
    }
    setPeriodOld(period);
    if (Number(d.day) === 30 && Number(d.month) === 11) {
    } else if (Number(d.day) === 31 && Number(d.month) === 11) {
    } else if (Number(d.day) === 1 && Number(d.month) === 0) {
    } else if (Number(d.day) === 2 && Number(d.month) === 0) {
    } else if (
      Number(d.day) < Number(new Date().getDate()) &&
      Number(d.month) <= Number(new Date().getMonth()) &&
      Number(d.year) <= Number(new Date().getFullYear())
    ) {
    } else if (d.booking === 1) {
    } else if (
      Number(new Date().getDate()) <= Number(d.day) &&
      Number(d.month) >= Number(new Date().getMonth()) &&
      Number(d.year) >= Number(new Date().getFullYear()) &&
      dateIn === "Выберите дату заезда"
    ) {
      periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);
      periodArr.forEach((p) => {
        var data = [];
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        if (data.includes(p) === true) arr.push(1);
      });
      setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
      setDateIn(
        `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
          String(d.month + 1).length === 1
            ? `0${Number(d.month) + 1}`
            : Number(d.month) + 1
        }.${d.year}`
      );
      setDateOut("Выберите дату отъезда");
      setPeriodHoverActive(true);
    } else if (
      Number(new Date().getDate()) > Number(d.day) &&
      Number(d.month) > Number(new Date().getMonth()) &&
      Number(d.year) >= Number(new Date().getFullYear()) &&
      dateIn === "Выберите дату заезда"
    ) {
      periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);
      periodArr.forEach((p) => {
        var data = [];
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        if (data.includes(p) === true) arr.push(1);
      });
      setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
      setDateIn(
        `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
          String(d.month + 1).length === 1
            ? `0${Number(d.month) + 1}`
            : Number(d.month) + 1
        }.${d.year}`
      );
      setDateOut("Выберите дату отъезда");
      setPeriodHoverActive(true);
    } else if (
      Number(new Date().getDate()) <= Number(d.day) &&
      Number(d.month) <= Number(new Date().getMonth()) &&
      Number(d.year) >= Number(new Date().getFullYear()) &&
      dateIn === "Выберите дату заезда"
    ) {
      periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);
      periodArr.forEach((p) => {
        var data = [];
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        if (data.includes(p) === true) arr.push(1);
      });
      setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
      setDateIn(
        `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
          String(d.month + 1).length === 1
            ? `0${Number(d.month) + 1}`
            : Number(d.month) + 1
        }.${d.year}`
      );
      setDateOut("Выберите дату отъезда");
      setPeriodHoverActive(true);
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(dateIn.split(".")[2])
    ) {
      if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);
        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }

      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(dateIn.split(".")[2])
    ) {
      if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[0]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[0]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[0]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[0]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[0]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 < Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(dateIn.split(".")[2])
    ) {
      periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

      periodArr.forEach((p) => {
        var data = [];
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));

        if (
          data.includes(p) === true &&
          data.reduce(function (prev, curr) {
            return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
              Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
              ? curr
              : prev;
          }) !== p
        )
          arr.push(1);
      });
      setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
      setDateIn(
        `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
          String(d.month + 1).length === 1
            ? `0${Number(d.month) + 1}`
            : Number(d.month) + 1
        }.${d.year}`
      );
      setDateOut("Выберите дату отъезда");
      setPeriodHoverActive(true);
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 < Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }

      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(dateIn.split(".")[2])
    ) {
      periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

      periodArr.forEach((p) => {
        var data = [];
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));

        if (
          data.includes(p) === true &&
          data.reduce(function (prev, curr) {
            return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
              Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
              ? curr
              : prev;
          }) !== p
        )
          arr.push(1);
      });
      setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
      setDateIn(
        `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
          String(d.month + 1).length === 1
            ? `0${Number(d.month) + 1}`
            : Number(d.month) + 1
        }.${d.year}`
      );
      setDateOut("Выберите дату отъезда");
      setPeriodHoverActive(true);
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(Number(dateIn.split(".")[2]))
    ) {
      if (Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (
        Number(dateIn.split(".")[0]) === d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }

      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(Number(dateIn.split(".")[2]))
    ) {
      if (
        Number(dateIn.split(".")[0]) === d.day &&
        Number(d.month) + 1 > Number(dateIn.split(".")[1])
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }

      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(Number(dateIn.split(".")[2]))
    ) {
      if (Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (
        Number(dateIn.split(".")[0]) === d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 < Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(Number(dateIn.split(".")[2]))
    ) {
      periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

      periodArr.forEach((p) => {
        var data = [];
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));

        if (
          data.includes(p) === true &&
          data.reduce(function (prev, curr) {
            return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
              Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
              ? curr
              : prev;
          }) !== p
        )
          arr.push(1);
      });
      setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
      setDateIn(
        `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
          String(d.month + 1).length === 1
            ? `0${Number(d.month) + 1}`
            : Number(d.month) + 1
        }.${d.year}`
      );
      setDateOut("Выберите дату отъезда");
      setPeriodHoverActive(true);
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 < Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(Number(dateIn.split(".")[2]))
    ) {
      if (Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }

      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(Number(dateIn.split(".")[2]))
    ) {
      periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

      periodArr.forEach((p) => {
        var data = [];
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));

        if (
          data.includes(p) === true &&
          data.reduce(function (prev, curr) {
            return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
              Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
              ? curr
              : prev;
          }) !== p
        )
          arr.push(1);
      });
      setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
      setDateIn(
        `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
          String(d.month + 1).length === 1
            ? `0${Number(d.month) + 1}`
            : Number(d.month) + 1
        }.${d.year}`
      );
      setDateOut("Выберите дату отъезда");
      setPeriodHoverActive(true);
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(Number(dateIn.split(".")[2]))
    ) {
      if (Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }

      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 < Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(Number(dateIn.split(".")[2]))
    ) {
      periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

      periodArr.forEach((p) => {
        var data = [];
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));

        if (
          data.includes(p) === true &&
          data.reduce(function (prev, curr) {
            return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
              Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
              ? curr
              : prev;
          }) !== p
        )
          arr.push(1);
      });
      setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
      setDateIn(
        `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
          String(d.month + 1).length === 1
            ? `0${Number(d.month) + 1}`
            : Number(d.month) + 1
        }.${d.year}`
      );
      setDateOut("Выберите дату отъезда");
      setPeriodHoverActive(true);
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 < Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(Number(dateIn.split(".")[2]))
    ) {
      if (Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }

      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(Number(dateIn.split(".")[2]))
    ) {
      if (d.month - Number(dateIn.split(".")[1]) + 1 >= 2) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = Number(dateIn.split(".")[1]) + 1; i <= d.month; i++) {
          for (var day = 1; day <= nDays[i - 1]; day++) {
            periodArr.push(day + "." + Number(i) + "." + d.year);
          }
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        if (Number(dateIn.split(".")[0]) > d.day) {
          for (
            var i = Number(dateIn.split(".")[0]);
            i <= nDays[Number(dateIn.split(".")[1]) - 1];
            i++
          ) {
            periodArr.push(
              i +
                "." +
                Number(dateIn.split(".")[1]) +
                "." +
                Number(dateIn.split(".")[2])
            );
          }
          for (var i = 1; i <= Number(d.day); i++) {
            periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
          }
        } else {
          for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
            periodArr.push(
              i +
                "." +
                Number(dateIn.split(".")[1]) +
                "." +
                Number(dateIn.split(".")[2])
            );
          }
        }
      }

      periodArr.forEach((p) => {
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length !== 0 &&
        arb.reduce(function (a, b) {
          return Number(a.split(".")[0]) < Number(b.split(".")[0]) ? a : b;
        }) === `${d.day}.${d.month + 1}.${d.year}`
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(Number(dateIn.split(".")[2]))
    ) {
      if (Number(dateIn.split(".")[2]) < Number(dateOut.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }

      var data = [];
      var arb = [];
      periodArr.forEach((p) => {
        Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
        data.map((ab) =>
          Number(ab.split(".")[1]) === Number(dateIn.split(".")[1]) &&
          Number(ab.split(".")[0]) >= Number(dateIn.split(".")[0])
            ? arb.push(ab)
            : null
        );
        if (arb.length !== 0) {
          if (
            data.includes(p) === true &&
            arb.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          ) {
            arr.push(1);
          }
        }
      });
      if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length === 0
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else if (
        period.length === periodOld.length &&
        arr.length === 0 &&
        arb.length !== 0 &&
        Number(
          arb
            .reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            })
            .split(".")[0]
        ) >= Number(d.day)
      ) {
        setDateOut(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setPeriodHover([]);
        setPeriodHoverActive(false);
      } else {
        periodArr.push(d.day + "." + Number(d.month + 1) + "." + d.year);

        periodArr.forEach((p) => {
          var data = [];
          Object.keys(jsonBooking).map((key) =>
            data.push(jsonBooking[key].date)
          );

          if (
            data.includes(p) === true &&
            data.reduce(function (prev, curr) {
              return Math.abs(curr.split(".")[0] - dateIn.split(".")[0]) <
                Math.abs(prev.split(".")[0] - dateIn.split(".")[0])
                ? curr
                : prev;
            }) !== p
          )
            arr.push(1);
        });
        setPeriodOld([d.day + "." + Number(d.month + 1) + "." + d.year]);
        setDateIn(
          `${String(d.day).length === 1 ? `0${d.day}` : d.day}.${
            String(d.month + 1).length === 1
              ? `0${Number(d.month) + 1}`
              : Number(d.month) + 1
          }.${d.year}`
        );
        setDateOut("Выберите дату отъезда");
        setPeriodHoverActive(true);
      }
    } else {
    }
  }
  function changeDateHover(d) {
    let arr = [];
    let periodArr = [];
    if (Number(d.day) === 30 && Number(d.month) === 11) {
    } else if (Number(d.day) === 31 && Number(d.month) === 11) {
    } else if (Number(d.day) === 1 && Number(d.month) === 0) {
    } else if (Number(d.day) === 2 && Number(d.month) === 0) {
    } else if (
      Number(d.day) < Number(new Date().getDate()) &&
      Number(d.month) <= Number(new Date().getMonth()) &&
      Number(d.year) <= Number(new Date().getFullYear())
    ) {
    } else if (d.booking === 1) {
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(dateIn.split(".")[2])
    ) {
      if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(d.year) > Number(dateIn.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(dateIn.split(".")[2])
    ) {
      if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(d.year) > Number(dateIn.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) < Number(d.day) &&
      Number(d.month) + 1 < Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(d.year) > Number(dateIn.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(d.year) > Number(dateIn.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (
        Number(dateIn.split(".")[0]) < d.day &&
        Number(dateIn.split(".")[1]) < d.month + 1
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(dateIn.split(".")[2])
    ) {
      if (
        Number(dateIn.split(".")[0]) === d.day &&
        Number(d.month) + 1 > Number(dateIn.split(".")[1])
      ) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(d.year) > Number(dateIn.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) === Number(d.day) &&
      Number(d.month) + 1 < Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(d.year) > Number(dateIn.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 === Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(d.year) > Number(dateIn.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 < Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(d.year) > Number(dateIn.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) === Number(dateIn.split(".")[2])
    ) {
      if (d.month - Number(dateIn.split(".")[1]) + 1 >= 2) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = Number(dateIn.split(".")[1]) + 1; i <= d.month; i++) {
          for (var day = 1; day <= nDays[i - 1]; day++) {
            periodArr.push(day + "." + Number(i) + "." + d.year);
          }
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        if (Number(dateIn.split(".")[0]) > d.day) {
          for (
            var i = Number(dateIn.split(".")[0]);
            i <= nDays[Number(dateIn.split(".")[1]) - 1];
            i++
          ) {
            periodArr.push(
              i +
                "." +
                Number(dateIn.split(".")[1]) +
                "." +
                Number(dateIn.split(".")[2])
            );
          }
          for (var i = 1; i <= Number(d.day); i++) {
            periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
          }
        } else {
          for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
            periodArr.push(
              i +
                "." +
                Number(dateIn.split(".")[1]) +
                "." +
                Number(dateIn.split(".")[2])
            );
          }
        }
      }
    } else if (
      Number(dateIn.split(".")[0]) > Number(d.day) &&
      Number(d.month) + 1 > Number(dateIn.split(".")[1]) &&
      Number(d.year) > Number(dateIn.split(".")[2])
    ) {
      if (Number(d.year) > Number(dateIn.split(".")[2])) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else if (Number(dateIn.split(".")[0]) > d.day) {
        for (
          var i = Number(dateIn.split(".")[0]);
          i <= nDays[Number(dateIn.split(".")[1]) - 1];
          i++
        ) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
        for (var i = 1; i <= Number(d.day); i++) {
          periodArr.push(i + "." + Number(d.month + 1) + "." + d.year);
        }
      } else {
        for (var i = Number(dateIn.split(".")[0]); i <= d.day; i++) {
          periodArr.push(
            i +
              "." +
              Number(dateIn.split(".")[1]) +
              "." +
              Number(dateIn.split(".")[2])
          );
        }
      }
    }
    periodArr.forEach((p) => {
      var data = [];
      Object.keys(jsonBooking).map((key) => data.push(jsonBooking[key].date));
      if (data.includes(p) === true) arr.push(1);
    });
    setPeriodHover(periodArr);
  }
  function onClose() {
    setInfo(false);
    setOpenQuestion(false);
    setOpenDate(false);
    setOpenCall(false);
    setOpenBooking(false);
    setOpenBlack(false);
    setOpenBlack(false);
    setPopupBooking(false);
    setOpenPolicy(false);
    setOpenRules(false);
    setOpenNY(false);
  }
  return (
    <div className="App">
      <Header />
      <Main
        scollToRef={scollToRef}
        setOpenDate={setOpenDate}
        setPopupBooking={setPopupBooking}
      />
      <Suspense fallback={<div></div>}>
        <GalleryContext.Provider value={gallery}>
          <Gallery />
        </GalleryContext.Provider>

        <Service setServiceHref={setServiceHref} />
        <PriceContext.Provider value={price}>
          <Price
            setOpenNY={setOpenNY}
            jsonPrice={jsonPrice}
            scollToRef={scollToRef}
            setOpenBooking={setOpenBooking}
          />
        </PriceContext.Provider>
        <Stocks />
        <IncludeContext.Provider value={include}>
          <Include />
        </IncludeContext.Provider>
        <Reviews />
        <Awards />
        <Video />
        <Booking
          setBookingRules={setBookingRules}
          setInfo={setInfo}
          scollToRef={scollToRef}
          setPayLink={setPayLink}
          setOpenPolicy={setOpenPolicy}
          setOpenRules={setOpenRules}
          setPopupBooking={setPopupBooking}
          stage={stage}
          matrix={matrix}
          period={period}
          periodHover={periodHover}
          changeDate={changeDate}
          changeDateHover={changeDateHover}
          month={month}
          months={months}
          year={year}
          setMonth={setMonth}
          setYear={setYear}
          periodHoverActive={periodHoverActive}
          dateIn={dateIn}
          dateOut={dateOut}
          place={place}
          setPlace={setPlace}
          animal={animal}
          setAnimal={setAnimal}
          setStage={setStage}
          vipSalePrice={vipSalePrice}
          price={priceBooking}
        />
        <QuestionsContext.Provider value={questions}>
          <Questions
            isServiceHref={isServiceHref}
            setOpenQuestion={setOpenQuestion}
          />
        </QuestionsContext.Provider>
        <AlongsideContext.Provider value={alongside}>
          <Alongside />
        </AlongsideContext.Provider>
        <Contacts setOpenCall={setOpenCall} />
        <Popup
          name="date"
          title="Узнать свободные даты"
          subtitle="Мы свяжемся с Вами и оповестим о свободных датах и стоимости, ответим на все вопросы."
          button="Узнать свободные даты"
          isOpen={isOpenDate}
          onClose={onClose}
          setThanks={setThanks}
        />
        <Popup
          name="new_year"
          title="Новый Год"
          subtitle="Мы свяжемся с Вами и перечислим способы, как можно забронировать коттедж на Новый Год."
          button="Заказать звонок"
          isOpen={isOpenNY}
          onClose={onClose}
          setThanks={setThanks}
        />
        <Popup
          name="call"
          title="Заказать звонок"
          button="Заказать звонок"
          isOpen={isOpenCall}
          onClose={onClose}
          setThanks={setThanks}
        />
        <Popup
          name="booking"
          title="Забронировать"
          button="Забронировать"
          isOpen={isOpenBooking}
          setThanks={setThanks}
          onClose={onClose}
        />
        <Popup
          name="question"
          title="Задать вопрос"
          button="Задать вопрос"
          isOpen={isOpenQuestion}
          onClose={onClose}
          question={question}
          setThanks={setThanks}
        >
          <div className="input__label">
            <span
              className={
                spanQuestion ? "input__span input__span_active" : "input__span"
              }
            >
              Ваш вопрос
            </span>
            <input
              className="form__input"
              type="text"
              onFocus={() => setSpanQuestion(true)}
              onBlur={(e) =>
                e.target.value === ""
                  ? setSpanQuestion(false)
                  : setSpanQuestion(true)
              }
              name="question"
              placeholder="Ваш вопрос"
              autoComplete="off"
              maxLength="200"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </div>
        </Popup>
        <Popup name="policy" isOpen={isOpenPolicy} onClose={onClose}>
          <Policy onClose={onClose} />
        </Popup>
        <Popup name="rules" isOpen={isOpenRules} onClose={onClose}>
          <Rules onClose={onClose} />
        </Popup>
        <Popup name="calendar__info" isOpen={isInfo} onClose={onClose}>
          <div className="calendar__info">
            <button
              type="button"
              className="calendar__info-btn"
              onClick={(e) => setInfo(false)}
            ></button>
            <img className="calendar__info-nyg" src={nyg} alt="nyg" />
            <h4 className="calendar__info-title">
              На Новый Год у нас 2 тарифа на выбор:
            </h4>
            <p className="calendar__info-text">
              30.12.2023 - 2.01.2024, стоимость:
              120&nbsp;000&nbsp;руб./3&nbsp;дня
            </p>
            <p className="calendar__info-text">
              31.12.2023 - 2.01.2024, стоимость:
              95&nbsp;000&nbsp;руб./2&nbsp;дня
            </p>
            <p className="calendar__info-text">
              Помимо стандартных удобств Вам будут также доступны:
            </p>
            <ul className="calendar__info-lists">
              <li className="calendar__info-list">Баня на весь срок аренды;</li>
              <li className="calendar__info-list">
                Веники и дрова для бани без ограничений;
              </li>
              <li className="calendar__info-list">
                5 доп.мест (при желании, можно увеличить количество спальных
                мест с 9 до 14);
              </li>
              <li className="calendar__info-list">
                Игровая приставка XBOX 360 на весь срок аренды;
              </li>
              <li className="calendar__info-list">Кальян (при желании);</li>
              <li className="calendar__info-list">
                Казан на 16 литров с шумовкой;
              </li>
              <li className="calendar__info-list">
                Новогодний подарок от нашего коттеджа (это сюрприз!).
              </li>
            </ul>
            {/* <p className="calendar__info-text_gift">
              При бронировании до 15.11.2023 дата 30.12.2023 - в подарок!🎁
            </p> */}
          </div>
        </Popup>
        <Popup name="booking__rules" isOpen={isBookingRules} onClose={onClose}>
          <div className="policy">
            <button
              type="button"
              className="popup__close"
              onClick={(e) => setBookingRules(false)}
            ></button>
            <h4 className="rules__title">
              Ознакомьтесь с 5 правилами осуществления бронирования:
            </h4>
            <p className="rules__subtitle">
              1. Бронирование коттеджа осуществляется мгновенно после внесения
              предоплаты. На Вашу почту придёт Подтверждение бронирования,
              которое является официальной гарантией заселения.
            </p>
            <p className="rules__subtitle">
              2. Мы возвращаем предоплату в полном объёме при отмене в течение 2
              часов после внесения предоплаты.
            </p>
            <p className="rules__subtitle">
              3. При отмене бронирования менее, чем за 14 дней до даты заезда,
              предоплата удерживается в полном объёме, но при отмене более, чем
              за 3 дня до заезда, возможен перенос даты заезда (доступно 1 раз и
              заезд нужно осуществить в течение 30 дней с отменённой даты), за
              исключением любых праздничных дней. При отмене более, чем за 14
              дней до даты заезда, мы полностью возвращаем предоплату. При
              бронировании на праздничные дни предоплата возвращается в полном
              объёме при отмене более 21 дня до даты заезда.
            </p>
            <p className="rules__subtitle">
              4. Предоплата составляет 50% от полной стоимости, оставшаяся часть
              – по заселении.
            </p>
            <p className="rules__subtitle">
              5. По заезде мы принимаем оплату любыми способами. Вместе с
              оплатой, на месте взимается залог на имущество в размере 5000 руб.
              (наличными или переводом на карту), который возвращается перед
              отъездом.)
            </p>
          </div>
        </Popup>
        <div
          className={isPopupBooking ? "popup popup_on" : "popup"}
          onClick={(e) => e.currentTarget === e.target && onClose()}
        >
          <Calendar
            setInfo={setInfo}
            class={"mini"}
            mouseMove={false}
            matrix={matrix}
            period={period}
            changeDate={changeDate}
            month={month}
            months={months}
            year={year}
            setMonth={setMonth}
            setYear={setYear}
            isPopupBooking={isPopupBooking}
            onClose={onClose}
          />
        </div>
        <Switch>
          <Route path="/thank-you">
            <Thanks />
          </Route>
          <Route path="/thanksforpay/:id?">
            <ThanksPay />
          </Route>
          <Route path="/payment">
            <Payment payLink={payLink} />
          </Route>
          <Route path="/errorpay">
            <ErrorPay />
          </Route>
          <Route path="/admin">
            <Admin nDays={nDays} />
          </Route>
        </Switch>
      </Suspense>
      <Footer />
      <section className="widget__button">
        <a className="widget__button-link" href="tel:+79312018040">
          {null}
        </a>
      </section>
    </div>
  );
}

export default App;
