import React from "react";
import gallery1 from "../images/g-mini/01.jpg";
import gallery2 from "../images/g-mini/02.jpg";
import gallery3 from "../images/g-mini/03.jpg";
import gallery4 from "../images/g-mini/04.jpg";
import gallery5 from "../images/g-mini/05.jpg";
import gallery6 from "../images/g-mini/06.jpg";
import gallery7 from "../images/g-mini/07.jpg";
import gallery8 from "../images/g-mini/08.jpg";
import gallery9 from "../images/g-mini/09.jpg";
import gallery10 from "../images/g-mini/10.jpg";
import gallery11 from "../images/g-mini/11.jpg";
import gallery12 from "../images/g-mini/12.jpg";
import gallery13 from "../images/g-mini/13.jpg";
import gallery14 from "../images/g-mini/14.jpg";
import gallery15 from "../images/g-mini/15.jpg";
import gallery16 from "../images/g-mini/16.jpg";
import gallery17 from "../images/g-mini/17.jpg";
import gallery18 from "../images/g-mini/18.jpg";
import gallery19 from "../images/g-mini/19.jpg";
import gallery20 from "../images/g-mini/20.jpg";
import gallery21 from "../images/g-mini/21.jpg";
import gallery22 from "../images/g-mini/22.jpg";
import gallery23 from "../images/g-mini/23.jpg";
import gallery24 from "../images/g-mini/24.jpg";
import gallery25 from "../images/g-mini/25.jpg";
import gallery26 from "../images/g-mini/26.jpg";
import gallery27 from "../images/g-mini/27.jpg";
import gallery28 from "../images/g-mini/28.jpg";
import gallery29 from "../images/g-mini/29.jpg";
import gallery30 from "../images/g-mini/30.jpg";
import gallery31 from "../images/g-mini/31.jpg";
import gallery32 from "../images/g-mini/32.jpg";
import gallery33 from "../images/g-mini/33.jpg";
import gallery34 from "../images/g-mini/34.jpg";
import galleryBig1 from "../images/gallery/01.jpg";
import galleryBig2 from "../images/gallery/02.jpg";
import galleryBig3 from "../images/gallery/03.jpg";
import galleryBig4 from "../images/gallery/04.jpg";
import galleryBig5 from "../images/gallery/05.jpg";
import galleryBig6 from "../images/gallery/06.jpg";
import galleryBig7 from "../images/gallery/07.jpg";
import galleryBig8 from "../images/gallery/08.jpg";
import galleryBig9 from "../images/gallery/09.jpg";
import galleryBig10 from "../images/gallery/10.jpg";
import galleryBig11 from "../images/gallery/11.jpg";
import galleryBig12 from "../images/gallery/12.jpg";
import galleryBig13 from "../images/gallery/13.jpg";
import galleryBig14 from "../images/gallery/14.jpg";
import galleryBig15 from "../images/gallery/15.jpg";
import galleryBig16 from "../images/gallery/16.jpg";
import galleryBig17 from "../images/gallery/17.jpg";
import galleryBig18 from "../images/gallery/18.jpg";
import galleryBig19 from "../images/gallery/19.jpg";
import galleryBig20 from "../images/gallery/20.jpg";
import galleryBig21 from "../images/gallery/21.jpg";
import galleryBig22 from "../images/gallery/22.jpg";
import galleryBig23 from "../images/gallery/23.jpg";
import galleryBig24 from "../images/gallery/24.jpg";
import galleryBig25 from "../images/gallery/25.jpg";
import galleryBig26 from "../images/gallery/26.jpg";
import galleryBig27 from "../images/gallery/27.jpg";
import galleryBig28 from "../images/gallery/28.jpg";
import galleryBig29 from "../images/gallery/29.jpg";
import galleryBig30 from "../images/gallery/30.jpg";
import galleryBig31 from "../images/gallery/31.jpg";
import galleryBig32 from "../images/gallery/32.jpg";
import galleryBig33 from "../images/gallery/33.jpg";
import galleryBig34 from "../images/gallery/34.jpg";

export const GalleryContext = React.createContext();

export const gallery = [
  {
    number: 0,
    src: `${gallery1}`,
    id: `${galleryBig1}`,
  },
  {
    number: 1,
    src: `${gallery2}`,
    id: `${galleryBig2}`,
  },
  {
    number: 2,
    src: `${gallery3}`,
    id: `${galleryBig3}`,
  },
  {
    number: 3,
    src: `${gallery4}`,
    id: `${galleryBig4}`,
  },
  {
    number: 4,
    src: `${gallery5}`,
    id: `${galleryBig5}`,
  },
  {
    number: 5,
    src: `${gallery6}`,
    id: `${galleryBig6}`,
  },
  {
    number: 6,
    src: `${gallery7}`,
    id: `${galleryBig7}`,
  },
  {
    number: 7,
    src: `${gallery8}`,
    id: `${galleryBig8}`,
  },
  {
    number: 8,
    src: `${gallery9}`,
    id: `${galleryBig9}`,
  },
  {
    number: 9,
    src: `${gallery10}`,
    id: `${galleryBig10}`,
  },
  {
    number: 10,
    src: `${gallery11}`,
    id: `${galleryBig11}`,
  },
  {
    number: 11,
    src: `${gallery12}`,
    id: `${galleryBig12}`,
  },
  {
    number: 12,
    src: `${gallery13}`,
    id: `${galleryBig13}`,
  },
  {
    number: 13,
    src: `${gallery14}`,
    id: `${galleryBig14}`,
  },
  {
    number: 14,
    src: `${gallery15}`,
    id: `${galleryBig15}`,
  },
  {
    number: 15,
    src: `${gallery16}`,
    id: `${galleryBig16}`,
  },
  {
    number: 16,
    src: `${gallery17}`,
    id: `${galleryBig17}`,
  },
  {
    number: 17,
    src: `${gallery18}`,
    id: `${galleryBig18}`,
  },
  {
    number: 18,
    src: `${gallery19}`,
    id: `${galleryBig19}`,
  },
  {
    number: 19,
    src: `${gallery20}`,
    id: `${galleryBig20}`,
  },
  {
    number: 20,
    src: `${gallery21}`,
    id: `${galleryBig21}`,
  },
  {
    number: 21,
    src: `${gallery22}`,
    id: `${galleryBig22}`,
  },
  {
    number: 22,
    src: `${gallery23}`,
    id: `${galleryBig23}`,
  },
  {
    number: 23,
    src: `${gallery24}`,
    id: `${galleryBig24}`,
  },
  {
    number: 24,
    src: `${gallery25}`,
    id: `${galleryBig25}`,
  },
  {
    number: 25,
    src: `${gallery26}`,
    id: `${galleryBig26}`,
  },
  {
    number: 26,
    src: `${gallery27}`,
    id: `${galleryBig27}`,
  },
  {
    number: 27,
    src: `${gallery28}`,
    id: `${galleryBig28}`,
  },
  {
    number: 28,
    src: `${gallery29}`,
    id: `${galleryBig29}`,
  },
  {
    number: 29,
    src: `${gallery30}`,
    id: `${galleryBig30}`,
  },
  {
    number: 30,
    src: `${gallery31}`,
    id: `${galleryBig31}`,
  },
  {
    number: 31,
    src: `${gallery32}`,
    id: `${galleryBig32}`,
  },
  {
    number: 32,
    src: `${gallery33}`,
    id: `${galleryBig33}`,
  },
  {
    number: 33,
    src: `${gallery34}`,
    id: `${galleryBig34}`,
  },
];
