import "./Policy.css";
import React from "react";

function Policy(props) {
  return (
    <section className="policy">
      <button className="popup__close" onClick={() => props.onClose()}></button>
      <h2 className="rules__title">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
      <p className="rules__subtitle">
        Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных
        данных» от 27.07.2006 года Вы подтверждаете свое согласие на обработку
        гражданином РФ Скутельским Артуром Владимировичем персональных данных:
        сбор, систематизацию, накопление, хранение, уточнение (обновление,
        изменение), использование, передачу исключительно в целях продажи на
        Ваше имя.
      </p>
      <p className="rules__subtitle">
        Гражданин РФ Скутельский Артур Владимирович гарантирует
        конфиденциальность получаемой информации. Обработка персональных данных
        осуществляется в целях эффективного исполнения заказов, договоров и иных
        обязательств, принятых Скутельским Артуром Владимировичем в качестве
        обязательных к исполнению.
      </p>
      <p className="rules__subtitle">
        Настоящее согласие распространяется на следующие Ваши персональные
        данные: фамилия, имя и отчество, адрес электронной почты, почтовый адрес
        доставки заказов, контактный телефон, платёжные реквизиты.
      </p>
      <p className="rules__subtitle">
        Срок действия согласия является неограниченным. Вы можете в любой момент
        отозвать настоящее согласие, направив письменное уведомление на адрес:
        Ленинградская область, Всеволожский р-н, пос. Токсово, Озёрный переулок,
        1 с пометкой «Отзыв согласия на обработку персональных данных».
      </p>
      <p className="rules__subtitle">
        Обращаем Ваше внимание, что отзыв согласия на обработку персональных
        данных влечёт за собой удаление Вашей учётной записи с Интернет-сайта
        heppo.ru, а также уничтожение записей, содержащих ваши персональные
        данные, в системах обработки персональных данных Скутельского Артура
        Владимировича, что может сделать невозможным пользование
        интернет-сервисами Скутельского Артура Владимировича.
      </p>
      <p className="rules__subtitle">
        Гарантирую, что представленная мной информация является полной, точной и
        достоверной, а также что при предоставлении информации не нарушаются
        действующее законодательство Российской Федерации, законные права и
        интересы третьих лиц. Вся представленная информация заполнена мною в
        отношении себя лично.
      </p>
      <p className="rules__subtitle">
        Настоящее согласие действует в течение всего периода хранения
        персональных данных, если иное не предусмотрено законодательством
        Российской Федерации.
      </p>
    </section>
  );
}

export default Policy;
