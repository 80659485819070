import "./Rules.css";
import React from "react";
import logosber from "../../images/logosber.png";
import logos from "../../images/logos.png";
import logos2 from "../../images/logos2.png";

function Policy(props) {
  return (
    <section className="policy">
      <button className="popup__close" onClick={() => props.onClose()}></button>
      <h2 className="rules__title">
        Правила оплаты и безопасность платежей, конфиденциальность информации
      </h2>
      <p className="rules__subtitle">
        Оплата банковскими картами осуществляется через ПАО «Сбербанк».
      </p>
      <img className="bank__img" src={logosber} alt="Сбербанк" />
      <p className="rules__subtitle">
        К оплате принимаются карты VISA, MasterCard, МИР.
      </p>
      <img className="bank__img" src={logos} alt="Логотипы платежных систем" />
      <p className="rules__subtitle">
        Услуга оплаты через интернет осуществляется в соответствии с Правилами
        международных платежных систем Visa, MasterCard и Платежной системы МИР
        на принципах соблюдения конфиденциальности и безопасности совершения
        платежа, для чего используются самые современные методы проверки,
        шифрования и передачи данных по закрытым каналам связи. Ввод данных
        банковской карты осуществляется на защищенной платежной странице ПАО
        «Сбербанк».
      </p>
      <p className="rules__subtitle">
        Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на
        платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача
        информации осуществляется в защищённом режиме с использованием протокола
        шифрования SSL. В случае если Ваш банк поддерживает технологию
        безопасного проведения интернет-платежей Verified By Visa, MasterCard
        SecureCode, MIR Accept, J-Secure, для проведения платежа также может
        потребоваться ввод специального пароля.
      </p>
      <p className="rules__subtitle">
        На странице для ввода данных банковской карты потребуется ввести данные
        банковской карты: номер карты, имя владельца карты, срок действия карты,
        трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard, Код
        Дополнительной Идентификации для МИР). Все необходимые данные
        пропечатаны на самой карте. Трёхзначный код безопасности — это три
        цифры, находящиеся на обратной стороне карты.
      </p>
      <p className="rules__subtitle">
        Далее вы будете перенаправлены на страницу Вашего банка для ввода кода
        безопасности, который придет к Вам в СМС. Если код безопасности к Вам не
        пришел, то следует обратиться в банк выдавший Вам карту.
      </p>
      <p className="rules__subtitle">
        Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
        сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК.
        Введённая информация не будет предоставлена третьим лицам за исключением
        случаев, предусмотренных законодательством РФ. Проведение платежей по
        банковским картам осуществляется в строгом соответствии с требованиями
        платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.
      </p>
      <img className="bank__img" src={logos2} alt="Логотипы платежных систем" />
      <p className="rules__subtitle">Случаи отказа в совершении платежа:</p>
      <ul>
        <li className="rules__subtitle">
          банковская карта не предназначена для совершения платежей через
          интернет, о чем можно узнать, обратившись в Ваш Банк;
        </li>
        <li className="rules__subtitle">
          недостаточно средств для оплаты на банковской карте. Подробнее о
          наличии средств на банковской карте Вы можете узнать, обратившись в
          банк, выпустивший банковскую карту;
        </li>
        <li className="rules__subtitle">
          данные банковской карты введены неверно;
        </li>
        <li className="rules__subtitle">
          истек срок действия банковской карты. Срок действия карты, как
          правило, указан на лицевой стороне карты (это месяц и год, до которого
          действительна карта). Подробнее о сроке действия карты Вы можете
          узнать, обратившись в банк, выпустивший банковскую карту;
        </li>
      </ul>
      <p className="rules__subtitle">
        По вопросам оплаты с помощью банковской карты и иным вопросам, связанным
        с работой сайта, Вы можете обращаться по следующим телефонам: 8 (931)
        201-80-40
      </p>
      <p className="rules__subtitle">
        Предоставляемая вами персональная информация (имя, адрес, телефон,
        e-mail, номер банковской карты) является конфиденциальной и не подлежит
        разглашению. Данные вашей кредитной карты передаются только в
        зашифрованном виде и не сохраняются на нашем Web-сервере.
      </p>

      <h2 className="rules__title">Правила возврата денежных средств</h2>
      <p className="rules__subtitle">
        При оплате картами возврат наличными денежными средствами не
        допускается. Порядок возврата регулируется правилами международных
        платежных систем.
      </p>
      <p className="rules__subtitle">
        Процедура возврата товара регламентируется статьей 26.1 федерального
        закона «О защите прав потребителей».
      </p>
      <p className="rules__subtitle">
        Для возврата денежных средств на банковскую карту необходимо заполнить
        «Заявление о возврате денежных средств», которое высылается по
        требованию компанией на электронный адрес и оправить его вместе с
        приложением копии паспорта по адресу heppoyarvi@gmail.com
      </p>
      <p className="rules__subtitle">
        Возврат денежных средств будет осуществлен на банковскую карту в течение
        21 (двадцати одного) рабочего дня со дня получения «Заявление о возврате
        денежных средств» Компанией.
      </p>
      <p className="rules__subtitle">
        Для возврата денежных средств по операциям проведенными с ошибками
        необходимо обратиться с письменным заявлением и приложением копии
        паспорта и чеков/квитанций, подтверждающих ошибочное списание. Данное
        заявление необходимо направить по адресу heppoyarvi@gmail.com
      </p>
      <p className="rules__subtitle">
        Сумма возврата будет равняться сумме покупки. Срок рассмотрения
        Заявления и возврата денежных средств начинает исчисляться с момента
        получения Компанией Заявления и рассчитывается в рабочих днях без учета
        праздников/выходных дней.
      </p>
    </section>
  );
}

export default Policy;
