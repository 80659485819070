import React from "react";
import alongside1 from "../images/alongside1.png";
import alongside2 from "../images/alongside2.png";
import alongside3 from "../images/alongside3.png";
import alongside4 from "../images/alongside4.png";
import alongside5 from "../images/alongside5.png";
import alongside6 from "../images/alongside6.png";
import alongside7 from "../images/alongside7.png";
import alongside8 from "../images/alongside8.png";
export const AlongsideContext = React.createContext();

export const alongside = [
  {
    id: "1",
    src: `${alongside1}`,
    title: "90 метров",
    subtitle: "До ближайшего пляжа озера Хепоярви",
  },
  {
    id: "2",
    src: `${alongside2}`,
    title: "650 метров",
    subtitle: "До горнолыжного курорта «Северный склон»",
  },
  {
    id: "3",
    src: `${alongside3}`,
    title: "600 метров",
    subtitle: "До станции вэйкбординга «Wakestation»",
  },
  {
    id: "4",
    src: `${alongside4}`,
    title: "700 метров",
    subtitle:
      "До бесплатного мини-зоопарка на «Северном Склоне», где можно кормить животных",
  },
  {
    id: "5",
    src: `${alongside5}`,
    title: "1000 метров",
    subtitle:
      "До магазинов «Норман», «Магнит», «Градусы» и «Пятёрочка», промтовары, аптека и кафе",
  },
  {
    id: "6",
    src: `${alongside6}`,
    title: "2500 метров",
    subtitle:
      "До ресторана «Гости» (можно приходить со своим алкоголем. Арабская, европейская, русская кухни)",
  },
  {
    id: "7",
    src: `${alongside7}`,
    title: "1000 метров",
    subtitle:
      "До знаменитого собора в Токсово (Св. Архистратига Божия Михаила И Всех Небесных Сил Бесплотных)",
  },
  {
    id: "8",
    src: `${alongside8}`,
    title: "1200 метров",
    subtitle: "До Лютеранской церкви с органными концертами по воскресеньям",
  },
];
