import "./Main.css";

function Main(props) {
  return (
    <section className={window.innerWidth <= 674 ? "main main_mob" : "main"}>
      <div className="main__bg">
        <h1 className="main__title">Коттедж с баней у озера в Токсово</h1>
        <p className="main__subtitle">Посуточная аренда</p>
        <p className="main__subtitle">Вместимость до 14 человек</p>
        <p className="main__subtitle">
          Безлимитная баня на дровах –{" "}
          <span className="main__span">БЕСПЛАТНО!</span>
        </p>
        <button
          className="main__button light"
          onClick={() => {
            props.scollToRef.current.scrollIntoView();
            props.setPopupBooking(true);
          }}
          type="button"
        >
          Узнать свободные даты
        </button>
        <a className="main__link" href="#stocks">
          Посмотреть акции🔥
        </a>
        {/* <button
          className="main__button light"
          onClick={() => {
            props.setOpenDate(true);
          }}
          type="button"
        >
          Узнать свободные даты
        </button> */}
      </div>
    </section>
  );
}

export default Main;
