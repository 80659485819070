import "./Payment.css";
import React from "react";
import { useHistory } from "react-router-dom";

function Payment(props) {
  const history = useHistory();
  function onClose() {
    history.push("/");
  }
  React.useEffect(() => {
    if (props.payLink != "") {
      window.location.replace(props.payLink);
    } else {
      onClose();
    }
  });
  return (
    <section
      className="payment"
      onClick={(e) => e.currentTarget === e.target && onClose()}
    >
      <div className="payment__box">
        <button
          className="payment__close"
          type="button"
          onClick={onClose}
        ></button>
        <h2 className="payment__title">
          Сейчас Вы будите перенаправлены на защищенный платежный шлюз
          Сбербанка.
        </h2>
        <p className="payment__text">Не обновляйте страницу.</p>
      </div>
    </section>
  );
}

export default Payment;
