import React from "react";
import include1 from "../images/include1.svg";
import include2 from "../images/include2.svg";
import include3 from "../images/include3.svg";
import include4 from "../images/include4.svg";
import include5 from "../images/include5.svg";
import include6 from "../images/include6.svg";
import include7 from "../images/include7.svg";
import include8 from "../images/include8.svg";
import include9 from "../images/include9.svg";
import include10 from "../images/include10.svg";
import include11 from "../images/include11.svg";
import include12 from "../images/include12.svg";
import include13 from "../images/include13.svg";
import include14 from "../images/include14.svg";
import include15 from "../images/include15.svg";
import include16 from "../images/include16.svg";
import include17 from "../images/include17.svg";
export const IncludeContext = React.createContext();

export const include = [
  {
    id: "1",
    title: "Комнаты",
    subtitle: [
      {
        id: "1r",
        src: `${include1}`,
        text: "3 спальни и гостиная",
      },
      {
        id: "2r",
        src: `${include2}`,
        text: "9 спальных мест",
      },
      {
        id: "3r",
        src: `${include3}`,
        text: "2 туалета, на 1 и 2 этажах",
      },
      {
        id: "4r",
        src: `${include4}`,
        text: "2 душевые",
      },
    ],
  },
  {
    id: "2",
    title: "Ассортимент",
    subtitle: [
      {
        id: "1a",
        src: `${include5}`,
        text: "Постельное белье для всех кроватей (застелено)",
      },
      {
        id: "2a",
        src: `${include6}`,
        text: "Полотенца",
      },
      {
        id: "3a",
        src: `${include7}`,
        text: "Полностью оборудованная кухня",
      },
      {
        id: "4a",
        src: `${include8}`,
        text: "Тапочки",
      },
      {
        id: "5a",
        src: `${include9}`,
        text: "Шампунь и кондиционер в душевых",
      },
      {
        id: "6a",
        src: `${include10}`,
        text: "Веники для бани (400р./шт)",
      },
    ],
  },
  {
    id: "3",
    title: "Техника",
    subtitle: [
      {
        id: "1t",
        src: `${include11}`,
        text: "Посудомоечная машина",
      },
      {
        id: "2t",
        src: `${include12}`,
        text: "Стиральная машина",
      },
      {
        id: "3t",
        src: `${include13}`,
        text: "Микроволновка",
      },
      {
        id: "4t",
        src: `${include14}`,
        text: "Духовой шкаф",
      },
      {
        id: "5t",
        src: `${include14}`,
        text: "Электрическая плита и вытяжка",
      },
      {
        id: "6t",
        src: `${include15}`,
        text: "Тостер, эл.чайник и кофемашина",
      },
      {
        id: "7t",
        src: `${include16}`,
        text: "2 фена",
      },
      {
        id: "8t",
        src: `${include17}`,
        text: "Wi-Fi и телевизор SmartTV (подписки на Okko и ivi)",
      },
    ],
  },
];
