import "./ThanksPay.css";
import React from "react";
import ok from "../../images/ok.svg";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

function ThanksPay() {
  const [star, setStar] = React.useState("");
  const [star2, setStar2] = React.useState("");
  const [star3, setStar3] = React.useState("");
  const [star4, setStar4] = React.useState("");
  const [star5, setStar5] = React.useState("");
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    //console.log(location.search.replace(/\?orderId=/, "").replace(/&lang=ru/, ""))
    if (location.search != "" && document.referrer != "") {
    } else {
      history.push("/");
    }
  });
  function onClose() {
    history.push("/");
  }
  function onSubmit(e) {
    e.preventDefault();
    let formData = new FormData();
    formData.append("star", star);
    formData.append("star2", star2);
    formData.append("star3", star3);
    formData.append("star4", star4);
    formData.append("star5", star5);
    axios({
      method: "POST",
      url: "../sendFeed.php",
      headers: {
        "Access-Control-Allow-Headers": "content-type",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: formData,
    }).then((response) => {
      if (response.data.result === "success") {
        onClose();
      }
    });
  }
  return (
    <section
      className="thankspay"
      onClick={(e) => e.currentTarget === e.target && onClose()}
    >
      <div className="thankspay__box">
        <button
          className="thankspay__close"
          type="button"
          onClick={onClose}
        ></button>
        <h2 className="thankspay__title">
          Спасибо за бронирование нашего коттеджа!
        </h2>
        <img className="thankspay__img" src={ok} alt="OK" />
        <p className="thankspay__text">
          Информация о Вашем бронировании выслана на Вашу почту.
        </p>
        <form className="thankspay__form">
          <p className="thankspay__rewiev">
            Легко ли было бронировать коттедж?
          </p>
          <div className="thankspay__star-box">
            <div
              className={
                star >= 1
                  ? "thankspay__star thankspay__star_active"
                  : "thankspay__star"
              }
              onClick={() => setStar(1)}
            ></div>
            <div
              className={
                star >= 2
                  ? "thankspay__star thankspay__star_active"
                  : "thankspay__star"
              }
              onClick={() => setStar(2)}
            ></div>
            <div
              className={
                star >= 3
                  ? "thankspay__star thankspay__star_active"
                  : "thankspay__star"
              }
              onClick={() => setStar(3)}
            ></div>
            <div
              className={
                star >= 4
                  ? "thankspay__star thankspay__star_active"
                  : "thankspay__star"
              }
              onClick={() => setStar(4)}
            ></div>
            <div
              className={
                star >= 5
                  ? "thankspay__star thankspay__star_active"
                  : "thankspay__star"
              }
              onClick={() => setStar(5)}
            ></div>
          </div>
          <div
            className={
              star >= 1
                ? "thankspay__box-rewiev thankspay__box-rewiev_active"
                : "thankspay__box-rewiev"
            }
          >
            <p className="thankspay__rewiev thankspay__rewiev_mini">
              Как Вам календарь, всё ли было понятно?
            </p>
            <div className="thankspay__star-box">
              <div
                className={
                  star2 >= 1
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar2(1)}
              ></div>
              <div
                className={
                  star2 >= 2
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar2(2)}
              ></div>
              <div
                className={
                  star2 >= 3
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar2(3)}
              ></div>
              <div
                className={
                  star2 >= 4
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar2(4)}
              ></div>
              <div
                className={
                  star2 >= 5
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar2(5)}
              ></div>
            </div>
            <p className="thankspay__rewiev thankspay__rewiev_mini">
              Не было ли сложностей с анкетой?
            </p>
            <div className="thankspay__star-box">
              <div
                className={
                  star3 >= 1
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar3(1)}
              ></div>
              <div
                className={
                  star3 >= 2
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar3(2)}
              ></div>
              <div
                className={
                  star3 >= 3
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar3(3)}
              ></div>
              <div
                className={
                  star3 >= 4
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar3(4)}
              ></div>
              <div
                className={
                  star3 >= 5
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar3(5)}
              ></div>
            </div>
            <p className="thankspay__rewiev thankspay__rewiev_mini">
              Как прошел процесс оплаты?
            </p>
            <div className="thankspay__star-box">
              <div
                className={
                  star4 >= 1
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar4(1)}
              ></div>
              <div
                className={
                  star4 >= 2
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar4(2)}
              ></div>
              <div
                className={
                  star4 >= 3
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar4(3)}
              ></div>
              <div
                className={
                  star4 >= 4
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar4(4)}
              ></div>
              <div
                className={
                  star4 >= 5
                    ? "thankspay__star thankspay__star_mini thankspay__star_active"
                    : "thankspay__star thankspay__star_mini"
                }
                onClick={() => setStar4(5)}
              ></div>
            </div>
            <p className="thankspay__rewiev thankspay__rewiev_mini">
              Ваши пожелания:
            </p>
            <textarea
              className="thankspay__text-input"
              type="text"
              name="about"
              placeholder=""
              autoComplete="off"
              maxLength="1000"
              value={star5}
              onChange={(e) => setStar5(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="thankspay__button"
            disabled={Number(star) >= 1 ? false : true}
            onClick={(e) => onSubmit(e)}
          >
            Отправить отзыв
          </button>
        </form>
      </div>
    </section>
  );
}

export default ThanksPay;
