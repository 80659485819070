import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p className="footer__text">ИП Скутельский Артур Владимирович</p>
      <p className="footer__text">ИНН/ОГРНИП: 470323535827/ 321470400013642;</p>
      <p className="footer__text">
        Юр.адрес: Лен.обл., п.Токсово, Озерный пер, 1;
      </p>
      <p className="footer__text">Тел: +7 (931) 201-80-40</p>
    </footer>
  );
}

export default Footer;
