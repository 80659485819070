import "./Stocks.css";
import React from "react";
import stocks1 from "../../images/stocks1.png";
import stocks2 from "../../images/stocks2.png";
import stocks3 from "../../images/stocks3.png";
import stocks4 from "../../images/stocks4.png";

function Stocks() {
  return (
    <section className="stocks" id="stocks">
      <h2 className="section__title">Наши акции🔥</h2>
      {/* <div className="stocks__box">
        <img className="stocks__img" src={stocks1} alt="ЧЁРНАЯ ПЯТНИЦА!" />
        <div className="stocks__text-box">
          <p className="stocks__title">ЧЁРНАЯ ПЯТНИЦА!</p>
          <p className="stocks__subtitle">
            Скидка 50% на будни (вс-чт) в декабре и январе при бронировании от
            2-х дней и более! (Кроме 30.12.2023 – 8.01.2024)
          </p>
          <p className="stocks__subtext">
            *Срок действия акции с 1.11.2023 по 30.11.2023.
          </p>
        </div>
      </div> */}
      <div className="stocks__box">
        <img className="stocks__img" src={stocks2} alt="stocks" />
        <div className="stocks__text-box">
          <p className="stocks__title">Кэшбек за пост!</p>
          <p className="stocks__subtitle">
            Сделай пост на своей странице ВКонтакте или Инстаграм* с фотографией
            гостей на фоне или внутри коттеджа, и мы вернём кэшбек 300 руб.
            переводом на карту!
          </p>
          <p className="stocks__subtext">
            *Запрещенная в России социальная сеть
          </p>
        </div>
      </div>
      <div className="stocks__box">
        <img className="stocks__img" src={stocks3} alt="stocks" />
        <div className="stocks__text-box">
          <p className="stocks__title">Заработаем вместе!</p>
          <p className="stocks__subtitle">
            Пригласите Ваших друзей/знакомых в наш коттедж, и в качестве
            благодарности после бронирования мы гарантированно переведём Вам
            1000 руб. на карту!
          </p>
          <p className="stocks__text">
            Для подтверждения бронирования по Вашей рекомендации понадобятся:
            дата заезда гостей, телефон и имя гостя, который забронировал
            коттедж.
          </p>
        </div>
      </div>
      <div className="stocks__box">
        <img className="stocks__img" src={stocks4} alt="stocks" />
        <div className="stocks__text-box">
          <p className="stocks__title">Весёлый вторник!</p>
          <p className="stocks__subtitle">
            Умеете снимать красивые фото и видео? Приезжайте во вторник по
            пониженной цене – 6000 руб.!
          </p>
          <p className="stocks__text">
            Свяжитесь с нами, мы назовём ближайший свободный вторник и
            забронируем его для Вас. Условие только одно – сделать минимум 10
            качественных фото и 5 видео (каждое не менее 10 секунд) с гостями во
            время отдыха и прислать нам. Юмор приветствуется!
          </p>
          <p className="stocks__subtext">
            *Акция не действует на праздники и в период с 1.06.2024 по
            31.08.2024. Также взимается залог на имущество 5000 руб.
            (возвращается перед отъездом). Внимание! Одна группа гостей может
            воспользоваться акцией 1 раз в год, допускается повторное
            бронирование в другом составе гостей.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Stocks;
