import "./Header.css";
import React from "react";
import logo from "../../images/logo.svg";
import logoBig from "../../images/logo.png";
import telegram from "../../images/telegram.svg";
import whatsapp from "../../images/whatsapp.svg";
import viber from "../../images/viber.svg";

function Header() {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <header className="header">
      <div className="header__box">
        <img
          className="header__logo"
          src={window.innerWidth <= 550 ? logo : logoBig}
          alt="Хеппо"
        />
        <div className="header__tel-box">
          <a className="header__tel" href="tel:+79219009294">
            +7 (921) 900-92-94
          </a>
          <a className="header__tel" href="tel:+79312018040">
            +7 (931) 201-80-40
          </a>
        </div>
        <div className="header__mess-box">
          <div className="mess__box">
            <a className="header__link" href="https://t.me/logrus_teatello">
              <img className="mess__icon" src={telegram} alt="telegram" />
            </a>
            <a
              className="header__link"
              href="whatsapp://send?phone=79312018040"
            >
              <img className="mess__icon" src={whatsapp} alt="whatsapp" />
            </a>
            <a className="header__link" href="viber://chat?number=+79312018040">
              <img className="mess__icon" src={viber} alt="viber" />
            </a>
          </div>
          <p className="header__text">Напишите нам</p>
        </div>
        <button
          className="header__button-nav"
          onClick={() => {
            setOpen(!isOpen);
          }}
        ></button>
      </div>
      <nav
        className={
          window.innerWidth <= 674
            ? isOpen
              ? "header__nav header__nav_active"
              : "header__nav"
            : "header__nav header__nav_active"
        }
      >
        <ul>
          <li>
            <a className="header__nav-link" href="#gallery">
              Фотогалерея
            </a>
          </li>
          <li>
            <a className="header__nav-link" href="#price">
              Цены и скидки
            </a>
          </li>
          <li>
            <a className="header__nav-link" href="#reviews">
              Отзывы
            </a>
          </li>
          <li>
            <a className="header__nav-link" href="#awards">
              Награды
            </a>
          </li>
          <li>
            <a className="header__nav-link" href="#contacts">
              Контакты
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
